<template>
    <div class="upload_image mt-2">
        <div id="newsletter-download-dropzone" class="dropzone">
            <div class="dz-message" data-dz-message>
                <div>
                    <img src="@/assets/images/image2.png">
                    <h4>Click to Upload</h4>
                    <p>or drag and drop</p>
                </div>
            </div>
        </div>
        <ul class="download_list mt-5 pt-5" v-if="downloads.length">
            <template v-for="(download, d) of downloads" :key="d">
                <li :class="{ 'has-error': filenameValidation && isDownloadEdited && selectedDownload.id == download.id }">
                    <h5 v-if="['xlsx', 'xlsm', 'xls'].includes(download.ext)">
                        <i class="far fa-file-excel mr-1"></i>
                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                    </h5>
                    <h5 v-else-if="['jpg', 'jpeg', 'png', 'pneg', 'gif', 'tif', 'bmp'].includes(download.ext)">
                        <i class="far fa-file-image mr-1"></i>
                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                    </h5>
                    <h5 v-else-if="['pdf'].includes(download.ext)">
                        <i class="far fa-file-pdf mr-1"></i>
                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                    </h5>
                    <h5 v-else-if="['mp4', 'mkv', 'wmv'].includes(download.ext)">
                        <i class="far fa-file-video mr-1"></i>
                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                    </h5>
                    <h5 v-else-if="['docx', 'doc', 'docm'].includes(download.ext)">
                        <i class="far fa-file-word mr-1"></i>
                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                    </h5>
                    <h5 v-else-if="['pptx', 'pptm', 'potx', 'potm', 'pot', 'ppsx'].includes(download.ext)">
                        <i class="far fa-file-powerpoint mr-1"></i>
                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                    </h5>
                    <h5 v-else-if="['mp3', 'pcm', 'wav', 'aac','ogg', 'wma'].includes(download.ext)">
                        <i class="far fa-file-audio mr-1"></i>
                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                    </h5>
                    <h5 v-else>
                        <i class="far fa-file mr-1"></i>
                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                    </h5>
                    <span class="download-action w-70">
                        <span v-if="isDownloadEdited && selectedDownload.id == download.id" class="text-om-blue">
                            <i class="fa fa-check pointer" @click="handleDownloadRename()"></i>
                        </span>
                        <span v-if="isDownloadEdited && selectedDownload.id == download.id" class="text-om-blue">
                            <i class="fa fa-times pointer" @click="selectedDownload = {}; isDownloadEdited = false; filenameValidation = false;"></i>
                        </span>
                        <span v-if="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)" class="text-om-blue">
                            <i class="far fa-edit pointer" @click="handleEditDownload(download)"></i>
                        </span>
                        <span v-if="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)" class="text-danger">
                            <i class="far fa-trash-alt pointer" @click="handleDownloadRemove(download.id)"></i>
                        </span>
                    </span>
                </li>
                <div class="w-100 text-danger download-error" v-if="filenameValidation && isDownloadEdited && selectedDownload.id == download.id">
                    The file name field is required.
                </div>
            </template>
        </ul>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import Dropzone from 'dropzone'
    import 'dropzone/dist/dropzone.css'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import Swal from 'sweetalert2'

    export default {
        name: 'Newsletter Download File',

        data () {
            return {
                form: {
                    newsletter_id: 0,
                },
                filenameValidation: false,
                isDownloadEdited: false,
                selectedDownload: {},
                downloads: [],
            };
        },

        props: {
            modelValue: [Object],
        },

        emits: ['update:modelValue'],

        watch: {
            newsletterDownloads (downloads) {
                const vm = this;

                vm.downloads = JSON.parse(JSON.stringify(downloads));
            },
        },

        computed: mapState({
            authToken: state => state.authModule.authToken,
            newsletterDownloads: state => state.newsletterModule.newsletterDownloads,
        }),

        mounted () {
            const vm = this;

            setTimeout(function () {
                vm.initDropzone();
            }, 1000);

            vm.resetForm();
            vm.getNewsletterDownloads(vm.modelValue.id);
        },

        methods: {
            ...mapActions({
                updateNewsletter: 'newsletterModule/updateNewsletter',
                getNewsletterDownloads: 'newsletterModule/getNewsletterDownloads',
                deleteDownload: 'newsletterModule/deleteDownload',
                renameDownloadFile: 'newsletterModule/renameDownloadFile',
            }),

            resetForm () {
                const vm = this;

                vm.form = {
                    newsletter_id: vm.modelValue.id,
                };

                vm.downloads = JSON.parse(JSON.stringify(vm.newsletterDownloads));
            },

            initDropzone () {
                const vm = this;

                const hasDropzoneEl = document.getElementById(`newsletter-download-dropzone`);

                if (hasDropzoneEl) {
                    if (vm.dropzone) {
                        vm.dropzone.destroy();
                    }

                    vm.dropzone = new Dropzone(`#newsletter-download-dropzone`, {
                        url: process.env.VUE_APP_API_URL+`/api/v3/newsletter/${vm.modelValue.id}/upload-download`,
                        uploadMultiple: true,
                        paramName: 'files',
                        headers: {
                            'Authorization': 'Bearer '+vm.authToken.access_token,
                            'accessed-location-id': vm.authToken.accessed_location_id,
                            'company-admin-id': vm.authToken.company_admin_id,
                        },
                        success: function (file, resp) {
                            vm.getNewsletterDownloads(vm.modelValue.id).then(() => {
                                if (file.previewElement) {
                                    file.previewElement.parentNode.removeChild(file.previewElement);

                                    setTimeout(function () {
                                        const el = document.getElementById(`#newsletter-download-dropzone`);

                                        if (el) {
                                            el.classList.remove('dz-started');
                                            el.classList.remove('dz-max-files-reached');
                                        }
                                    }, 500);
                                }
                            });
                        },
                    });
                }
            },

            handleEditDownload (download) {
                const vm = this;

                vm.selectedDownload = JSON.parse(JSON.stringify(download));
                vm.isDownloadEdited = true;
                vm.filenameValidation = false;
            },

            handleDownloadRemove (id) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this file', 'Delete')
                      option.preConfirm = function() { return vm.deleteDownload({ id, newsletter_id: vm.modelValue.id }); };

                Swal.fire(option);
            },

            handleDownloadRename () {
                const vm = this;

                if (vm.selectedDownload.filename && vm.selectedDownload.filename.trim()) {
                    vm.filenameValidation = false;

                    vm.renameDownloadFile(vm.selectedDownload).then((result) => {
                        if (result) {
                            const index = vm.downloads.findIndex((download) => download.id == vm.selectedDownload.id);

                            vm.downloads[index].filename = vm.selectedDownload.filename;
                            vm.selectedDownload = {};
                            vm.isDownloadEdited = false;
                        }
                    });
                } else {
                    vm.filenameValidation = true;
                }
            },
        },
    }
</script>

<style scoped>
    :deep(.newsletter-media-headline-setting-form) {
        height: calc(100vh - 180px);
    }

    :deep(.newsletter-media-headline-setting-form .group_item) {
        width: 100%;
    }

    :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }

    .w-70 {
        width: 70px !important;
    }

    .download-action span {
        border-left: 0px !important;
        height: 100% !important;
    }

    .download-action span +  span {
        border-left: 1px solid #eaeaea !important;
    }

    .download_list {
        padding-top: 5px;
    }

    .download_list li {
        border: 1px solid #eaeaea;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 10px;
        min-height: 40px;
    }

    .download_list li img {
        width: 40px;
        height: auto;
    }

    .download_list li h5 {
        padding: 0 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .download_list li span {
        margin-left: auto;
        font-size: 12px;
        width: 30px;
        height: auto;
        border-left: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .download_list h5 {
        width: 100%;
    }

    .download-editor {
        width: 100%;
        height: 100%;
        background: transparent;
    }

    .download-error {
        margin-bottom: 10px;
        margin-top: -10px;
        text-align: left;
        font-size: 14px;
    }

    li.has-error {
        border-color: #eb1414;
    }

    .dropzone {
        border: 1.2px dashed #95bfff;
        background: #fafcfd;
        min-height: 150px;
    }
</style>
